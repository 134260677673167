import { H2 } from "../H2";
import { CarouselArrow } from "~/assets/icons/CarouselArrow";
import { DBProductInfo } from "../../../../types/ProductInfo";
import { LargeProduct } from "./Product";
import { useEffect, useRef, useState } from "react";

export const ProductRow = ({
  products,
  title,
  subtitle,
}: {
  products: DBProductInfo[];
  title: string;
  subtitle?: string;
}) => {
  const index = products.length;
  const containerRef = useRef<HTMLDivElement>(null);
  const [clickIndex, setClickIndex] = useState(0);

  useEffect(() => {
    if (clickIndex < 0 || clickIndex >= products.length) return;

    containerRef.current?.scrollTo({
      behavior: "smooth",
      left: clickIndex * 346 + clickIndex * 8, //These magic numbers come from LargeProduct.tsx width on desktop + padding
    });
  }, [clickIndex]);

  return (
    <div className="ml-0 px-6 md:ml-[176px] md:mr-[20px] md:px-0">
      <div className="mb-10 flex flex-row items-center">
        <H2>{title}</H2>
        {subtitle && (
          <div className="ml-10 text-base font-normal">{subtitle}</div>
        )}
        <div className="absolute right-10  hidden flex-row items-center gap-2 md:block">
          <div
            className="cursor-pointer"
            onClick={() => {
              setClickIndex((prev) => prev + 1);
            }}
          >
            <CarouselArrow isActive={true} />
          </div>
        </div>
      </div>
      <div className="flex flex-row overflow-x-scroll">
        <div
          className="no-scrollbar edge-mask mb-8 mt-5 flex snap-x snap-mandatory gap-8 overflow-auto md:mt-10"
          ref={containerRef}
        >
          {products.map((product, i) => (
            <LargeProduct
              key={product.slug}
              url={product.image.id}
              brand={product.brand?.name || ""}
              title={product.name}
              slug={product.slug}
              price={product.priceFrom}
              discount={product.discount || 0}
              soldOut={product.soldOut}
              height={
                i % 3 === 0
                  ? "h-[502px]"
                  : i % 3 === 1
                    ? "h-[371px]"
                    : "h-[443px]"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
